<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ this.$t('general.manage_menu_modules') }}</li>
          </ul>
        </nav>
      </div>

      <div class="uk-alert-success" uk-alert v-if="successMessage">
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="uk-alert-danger" uk-alert v-show="errorMessage">
        <a class="uk-alert-close" uk-close></a>
        <p style="color: red">
          <i class="uil-exclamation-triangle"></i> {{ errorMessage }}
        </p>
      </div>

      <default-loading v-if="loading"></default-loading>
      <template v-else>
        <div class="card">
          <!-- Card header -->
          <div class="card-header actions-toolbar border-0">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="col-form-label">Kurum Seçiniz</label>
                <select v-model="companyId" class="selectpicker1" @change="getCompanyModule">
                  <option value="">{{ this.$t('general.choose') }}</option>
                  <option v-for="item in companyList" :value="item.id" :key="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>

              <div class="col-12" v-if="companyId">

                <div class="d-flex justify-content-between align-items-center">
                  <h4 class="mb-3 mt-3"><i class="uil-th"></i> {{ this.$t('general.manage_modules') }}</h4>
                </div>

                <!-- <multiselect v-model="modules" tag-placeholder="Modül Seçiniz" placeholder="Modül Seçiniz" label="name"
                  track-by="id" :options="moduleList" :allow-empty="true" :searchable="true" deselectLabel="Modül Seçin"
                  selectLabel="Seçiniz" :multiple="true" /> -->

                <!-- <div class="uk-accordion-content mt-0">
                  <div>
                    <div class="sec-list-item">
                      <div>
                        <i class="uil-list-ul mr-2"></i>
                        <p>Liderler Tablosu</p>
                      </div>
                      <label class="uk-switch" for="repeatable">
                        <input type="checkbox" id="repeatable" v-model="repeatable" />
                        <div class="uk-switch-slider"></div>
                      </label>
                    </div>
                    <div class="sec-list-item">
                      <div>
                        <i class="uil-list-ul mr-2"></i>
                        <p>Katalog Eğitimleri</p>
                      </div>
                      <label class="uk-switch" for="repeatable">
                        <input type="checkbox" id="repeatable" v-model="repeatable" checked />
                        <div class="uk-switch-slider"></div>
                      </label>
                    </div>
                    <div class="sec-list-item">
                      <div>
                        <i class="uil-list-ul mr-2"></i>
                        <p>Koleksiyonlar</p>
                      </div>
                      <label class="uk-switch" for="repeatable">
                        <input type="checkbox" id="repeatable" v-model="repeatable" checked />
                        <div class="uk-switch-slider"></div>
                      </label>
                    </div>
                    <div class="sec-list-item">
                      <div>
                        <i class="uil-list-ul mr-2"></i>
                        <p>Hediyeler</p>
                      </div>
                      <label class="uk-switch" for="repeatable">
                        <input type="checkbox" id="repeatable" v-model="repeatable" checked />
                        <div class="uk-switch-slider"></div>
                      </label>
                    </div>
                  </div>
                </div> -->
                <draggable class="uk-accordion-content mt-0" :list="moduleList?.menu" group="education_widget">
                  <div v-for="(module, index) in moduleList?.menu" :key="'assignment_content_list_' + index">
                    <div class="sec-list-item">
                      <div>
                        <i class="uil-list-ul mr-2"></i>
                        <p>{{ module.title }}</p>
                      </div>
                      <div>
                        <div class="btn-act">
                          <!-- <a href="#" uk-tooltip="title:Önizleme"
                                  ><i class="uil-eye"></i
                                ></a> -->
                          <label class="uk-switch">
                            <input type="checkbox" v-model="module.status" />
                            <div class="uk-switch-slider"></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <hr>

                <div class="d-flex justify-content-between align-items-center">
                  <h4 class="mt-3 mb-3"><i class="uil-window-section"></i> Giriş Sayfası Yönetimi</h4>
                </div>
                <draggable class="uk-accordion-content mt-0" :list="moduleList.dashboard" group="education_widget">
                  <div v-for="(module, index) in moduleList.dashboard" :key="'assignment_content_list_' + index">
                    <div class="sec-list-item">
                      <div>
                        <i class="uil-list-ul mr-2"></i>
                        <p>{{ module.title }}</p>
                      </div>
                      <div>
                        <div class="btn-act">

                          <label class="uk-switch">
                            <input type="checkbox" v-model="module.status" />
                            <div class="uk-switch-slider"></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
          <div class="uk-flex uk-flex-right p-4">
            <button type="button" class="btn btn-default" @click="save">
              {{ $t("general.save") }}
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";
import moment from "moment";
import axios from "axios";
import Multiselect from "vue-multiselect";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: 'aes', isCompression: true });
export default {
  name: "ModuleList",
  components: { DataTable, Multiselect, DefaultLoading, draggable },
  data() {
    return {
      repeatable: false,
      errorMessage: null,
      successMessage: null,
      companyList: [],
      companyId: null,
      loading: true,
      moduleList: {
        // {
        //   id: "leader_table",
        //   name: this.$t('menu_modules.leader_table'),
        //   selected: false
        // },
        // {
        //   id: "collections",
        //   name: this.$t('menu_modules.collections'),
        //   selected: false
        // },
        // {
        //   id: "gifts",
        //   name: this.$t('menu_modules.gifts'),
        //   selected: false
        // },
        // {
        //   id: "catalog_training",
        //   name: this.$t('menu_modules.catalog_training'),
        //   selected: false
        // },
        // {
        //   id: "social_posts",
        //   name: this.$t('menu_modules.social_posts'),
        //   selected: false
        // },
      },
      modules: [],
      headers: this.apiHeaders,
    };
  },
  computed: {},
  methods: {
    save() {
      let self = this;
      // axios.post(process.env.VUE_APP_BACKEND_APP_URL + "/api/set-company-modules", {
      //   params: {
      //     company_id: self.companyId,
      //     modules: self.modules,
      //   },
      // })
      //   .then((response) => {
      //     if (response.statusText == "OK") {
      //       self.successMessage = "İşlem Başarıyla Tamamlandı"
      //     } else {
      //       self.errorMessage.push("İşlem Sırasında Bir Sorun Oluştu");
      //     }
      //   });
      let sendData = [];
      console.log(this.moduleList['dashboard']);
      this.moduleList['dashboard'].forEach((element, index) => {
        sendData.push({
          group_name: 'dashboard',
          type: element.type,
          title: element.title,
          order: index,
          status: element.status
        })
      });
      this.moduleList['menu'].forEach((element, index) => {
        sendData.push({
          group_name: 'menu',
          type: element.type,
          title: element.title,
          order: index,
          status: element.status
        })
      });
      // sendData.push({
      //     group_name: 'menu',
      //     type: 'my-collection',
      //     title: 'Koleksiyonlar',
      //     order: 15,
      //     status: 1
      //   })
      // for (let i = 0; i < sendData.length; i++) {
      //   sendData[i]['group_name'] = 'dashboard';
      // };
      axios.post(process.env.VUE_APP_BACKEND_APP_URL + "/api/widgets", {
        params: {
          company_id: self.companyId,
          widgets: sendData
        },
      })
        .then((response) => {
          if (response['status'] === 200) {
            let menu = [];
            let dashboard = [];
            response?.data.forEach(element => {
              if (element.group_name === 'menu') {
                menu.push(element)
              } else if (element.group_name === 'dashboard') {
                dashboard.push(element)
              }
            });
            let test = {
              'menu': menu,
              'dashboard': dashboard
            }
      
            ls.set("moduleList", test)
            // window.localStorage.setItem("moduleList", test);
            self.successMessage = "İşlem Başarıyla Tamamlandı";
          } else {
            self.errorMessage.push("İşlem Sırasında Bir Sorun Oluştu");
          }
          window.scrollTo(0, 0);
        });
    },

    getCompanies() {
      let self = this;
      axios
        .get(process.env.VUE_APP_BACKEND_APP_URL + "/api/get-companies", {
          headers: self.headers,
        })
        .then((response) => {
          let items = response.data;
          items.forEach((item) => {
            self.companyList.push({
              id: item.id,
              name: item.name,
            });
            self.loading = false
          });
        });
    },
    getCompanyModule() {
      let self = this;
      this.modules = [];
      // axios.get(
      //   process.env.VUE_APP_BACKEND_APP_URL +
      //   "/api/get-company-modules/" +
      //   self.companyId,
      //   {
      //     headers: self.headers,
      //   }
      // ).then((response) => {
      //   let items = JSON.parse(response.data.json);
      //   let value = "";
      //   items.forEach((item) => {
      //     switch (item) {
      //       case "leader_table":
      //         value = this.$t('menu_modules.leader_table');
      //         break;
      //       case "collections":
      //         value = this.$t('menu_modules.collections');
      //         break;
      //       case "gifts":
      //         value = this.$t('menu_modules.gifts');
      //         break;
      //       case "catalog_training":
      //         value = this.$t('menu_modules.catalog_training');
      //         break;
      //       case "social_posts":
      //         value = this.$t('menu_modules.social_posts');
      //         break;
      //     }
      //     self.modules.push({
      //       id: item,
      //       name: value,
      //       selected: true
      //     });
      //     this.moduleList.every((module, index) => {
      //       if (module.id === item) {
      //         this.moduleList[index].selected = true
      //         return false;
      //       } else {
      //         return true;
      //       }
      //     });
      //   });
      // });
      axios.get(process.env.VUE_APP_BACKEND_APP_URL + "/api/widgets", {
        params: {
          company_id: self.companyId
        },
      })
        .then((response) => {
          this.moduleList = response.data;
          // if (response.statusText == "OK") {
          //   self.successMessage = "İşlem Başarıyla Tamamlandı"
          // } else {
          //   self.errorMessage.push("İşlem Sırasında Bir Sorun Oluştu");
          // }
        });
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    this.getCompanies();
  },
  watch: {
    errors: function (val) {
      if (val) {
        this.scrollToTop();
        this.errorMessages.push(val);
      }
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
    companyList(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker1").selectpicker();
        }, 200);
      }
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 5000);
  },
};
</script>
<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.form-group.list input[type="number"] {
  padding: 15px 0 15px 10px !important;
}

input[type="number"]:focus {
  border: 1px solid #e2e5ec !important;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
